<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import Container from '../atoms/Container.svelte'
    import Logo from '../atoms/Logo.svelte'

    interface Props extends HTMLAttributes<HTMLElement> {
        logo?: Snippet
        page?: Snippet
        children: Snippet
    }

    const {
        class: classNames = '',
        logo,
        page,
        children,
        ...rest
    }: Props = $props()
</script>

<header class="header {classNames}" {...rest}>
    <Container class="flex items-center justify-between">
        <div class="flex items-center justify-start gap-x-3">
            <a aria-label="Back to homepage" href="/">
                {#if logo}
                    {@render logo()}
                {:else}
                    <Logo />
                {/if}
            </a>

            {#if page}
                <span class="mt-1 font-display text-lg">
                    {@render page?.()}
                </span>
            {/if}
        </div>

        {@render children()}
    </Container>
</header>

<style global lang="postcss">
    .header {
        position: sticky;
        top: 0;
        z-index: theme('zIndex.40');
        padding-top: theme('spacing.3');
        padding-bottom: theme('spacing.3');
        color: theme('colors.white');
        background: var(--primary-700, var(--nmd-800));

        .link {
            padding: theme('spacing.1') theme('spacing.2');
            text-decoration: none;

            @screen sm {
                padding: theme('spacing.1') theme('spacing.3');
            }
        }

        .dropdown-link {
            padding-right: theme('spacing.1');

            @screen sm {
                padding-right: theme('spacing.2');
            }
        }

        .dropdown-menu {
            top: calc(100% + theme('spacing.1'));
            right: -1px;
            border: theme('colors.nmd-700') solid 1px;
        }
    }
</style>
