<script lang="ts">
    import { getSiteLink, Site } from '@nomodo/links'
    import { closeMenu, Flex, Header, Link, Menu } from '@nomodo/ui'
    import type { Snippet } from 'svelte'

    import { page } from '$app/stores'

    interface Props {
        children: Snippet
    }

    const { children }: Props = $props()
</script>

<Header>
    <Menu>
        {#snippet top()}
            <Flex class="gap-1 sm:gap-2 lg:gap-3" alignItems="center">
                <Link
                    active={$page.url.pathname === '/apps'}
                    href="/apps"
                    onclick={closeMenu}
                >
                    Apps
                </Link>
            </Flex>
        {/snippet}

        <Link
            active={$page.url.pathname === '/about'}
            href="/about"
            onclick={closeMenu}
        >
            About us
        </Link>
        <Link
            hover
            href={`${getSiteLink(Site.admin)}/login`}
            onclick={closeMenu}
        >
            Log in
        </Link>
    </Menu>
</Header>

<main>
    {@render children()}
</main>
