<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLAnchorElement> {
        active?: boolean
        hover?: boolean
        href: string
        children: Snippet
        target?: string
        rel?: string
    }

    const {
        class: classNames = '',
        target = undefined,
        rel = undefined,
        active = false,
        hover = false,
        href,
        children,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses([
            'link',
            active ? 'active' : '',
            hover ? 'hover' : '',
            classNames,
        ])
    )
</script>

<a class={classes} {href} {...rest} {rel} {target}>
    {@render children()}
</a>

<style global lang="postcss">
    .link {
        padding: theme('spacing.1') theme('spacing.3');
        text-decoration: underline;
        border-radius: theme('borderRadius.sm');
    }

    .link:hover,
    .link.hover {
        text-decoration: none;
        color: theme('colors.white');
        background-color: theme('colors.green.600');
    }

    .link.active {
        text-decoration: none;
        color: theme('colors.white');
        background-color: theme('colors.nmd-700');
    }
</style>
